<template>
  <v-carousel delimiter-icon="mdi-record-circle">
    <v-carousel-item
      reverse-transition="fade-transition"
      transition="fade-transition"
    >
      <v-row
        class="d-flex align-center justify-center fill-height px-16 black--text"
      >
        <v-col cols="12" sm="12" md="6">
          <div v-if="casesuccess.src && !casesuccess.link" class="display-2">
            <v-img
              max-width="100%"
              max-height="300px"
              :src="casesuccess.src"
            ></v-img>
          </div>
          <div v-else class="display-2">
            <iframe
              width="100%"
              height="300px"
              :src="'https://www.youtube.com/embed/' + url"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <div class="text-left subtitle-1">
            <p class="text-h6">{{ casesuccess.title }}</p>
            <div class="divider"></div>
            <p>
              {{ casesuccess.text }}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
export default {
  name: "PreviewCaseSuccess",
  data: () => ({ url: "3y_8wMcxzmM" }),
  props: { casesuccess: Object },
  watch: {
    casesuccess: {
      handler: function(val, oldVal) {
        let url_arr = val.link.split("v=");
        if (url_arr[1]) {
          this.url = url_arr[1];
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
