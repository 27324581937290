<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0">Casos de Éxito</p>
      </div>
    </v-banner>
    <v-container class="pd-0">
      <v-row>
        <!-- Form -->
        <v-col cols="12" sm="12" md="4">
          <v-form>
            <v-container>
              <v-row
                v-if="
                  slcCaseSuccess.id_article && searchInArray(actions, 'Editar')
                "
              >
                <div class="text-h6">
                  Editar Caso de Éxito
                </div>
                <v-col cols="12" sm="12" xl="9">
                  <v-text-field
                    label="Título"
                    outlined
                    hide-details
                    v-model="slcCaseSuccess.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xl="3">
                  <v-text-field
                    label="Posición"
                    outlined
                    hide-details
                    v-model="slcCaseSuccess.position"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea
                    label="Descripción"
                    outlined
                    auto-grow
                    hide-details
                    v-model="slcCaseSuccess.text"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="URL Youtube"
                    outlined
                    v-model="slcCaseSuccess.link"
                    hint="Ejemplo: https://www.youtube.com/watch?v=3y_8wMcxzmM"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-file-input
                    color="blue accent-4"
                    counter
                    label="Archivo de imagen"
                    placeholder="Seleccione una imagen (250x500)"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    outlined
                    clear-icon
                    :show-size="1000"
                    v-model="slcCaseSuccess.image"
                    @change="previewUpdate"
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="4">
                  <v-btn color="primary" text block @click="cleanUpdate">
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="8">
                  <v-btn
                    color="primary"
                    block
                    @click="update"
                    :loading="isLoading"
                  >
                    Editar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  !slcCaseSuccess.id_article &&
                    searchInArray(actions, 'Agregar')
                "
              >
                <div class="text-h6">
                  Agregar Caso de Éxito
                </div>
                <v-col cols="12" sm="12" xl="9">
                  <v-text-field
                    label="Título"
                    outlined
                    hide-details
                    v-model="newCaseSuccess.title"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" xl="3">
                  <v-text-field
                    label="Posición"
                    outlined
                    hide-details
                    v-model="newCaseSuccess.position"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea
                    label="Descripción"
                    outlined
                    auto-grow
                    hide-details
                    v-model="newCaseSuccess.text"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    label="URL Youtube"
                    outlined
                    v-model="newCaseSuccess.link"
                    hint="Ejemplo: https://www.youtube.com/watch?v=3y_8wMcxzmM"
                    persistent-hint
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-file-input
                    color="blue accent-4"
                    counter
                    label="Archivo de imagen"
                    placeholder="Seleccione una imagen"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    outlined
                    clear-icon
                    :show-size="1000"
                    v-model="newCaseSuccess.image"
                    @change="previewAdd"
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="4">
                  <v-btn color="primary" text block @click="cleanAdd">
                    Cancelar
                  </v-btn>
                </v-col>
                <v-col cols="8">
                  <v-btn
                    color="primary"
                    block
                    @click="add"
                    :loading="isLoading"
                  >
                    Agregar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="8"
          v-if="
            searchInArray(actions, 'Editar') ||
              searchInArray(actions, 'Agregar')
          "
        >
          <PreviewCaseSuccess
            :casesuccess="
              slcCaseSuccess.id_article ? slcCaseSuccess : newCaseSuccess
            "
          />
          <p class="text-center text-caption">
            <v-icon color="black">mdi-information-outline</v-icon>
            Una URL de Youtube se priorisará ante una imagen.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Ver')"
              :headers="data.headers"
              :items="data.data"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat height="50">
                  <v-toolbar-title class="text-h5">
                    Lista de casos de éxito
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon>mdi-file-excel</v-icon>
                          Descargar CSV
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="searchInArray(actions, 'Editar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="selectCaseSuccess(item)"
                        color="amber"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Eliminar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectCaseSuccess(item);
                          modalDelete();
                        "
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Activar/Desactivar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectCaseSuccess(item);
                          changeStatus();
                        "
                        :color="
                          item.status == 1
                            ? 'light-blue darken-3'
                            : 'blue-grey darken-1'
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          item.status == 1
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }}
                      </v-icon>
                    </template>
                    <span>
                      {{ item.status == 1 ? "Activar" : "Desactivar" }}
                    </span>
                  </v-tooltip>
                </template>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getCases">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogDelete" max-width="450px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-card-text class="text-center">
          <p class="mb-0">¿Está seguro de eliminar este elemento?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">
            Cancelar
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="deleteCaseSuccessConfirm"
            :loading="isLoading"
            >Eliminar</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PreviewCaseSuccess from "./PreviewCase.vue";

export default {
  name: "CaseSuccess",
  components: {
    PreviewCaseSuccess,
  },
  data: () => ({
    data: {
      load: true,
      headers: [
        {
          text: "Título",
          value: "title",
        },
        {
          text: "Descripción",
          value: "text",
        },
        {
          text: "Posición",
          value: "position",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
      ],
      data: [],
    },
    newCaseSuccess: {
      title: "Titulo",
      text:
        " Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus laudantium mollitia corrupti.",
      link: "",
      src: "",
      position: 1,
    },
    slcCaseSuccess: {},
    dialogDelete: false,
    isLoading: false,
    search: "",
  }),
  mounted() {
    this.getCurrentActions("Casos");
    this.getCases();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionalidad
    getCases: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "success/all")
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    selectCaseSuccess(casesuccess) {
      let src = casesuccess.image;
      casesuccess.src = this.img + "success/" + src;
      casesuccess.image = null;
      this.slcCaseSuccess = casesuccess;
    },

    cleanAdd() {
      this.newCaseSuccess = {
        title: "Titulo",
        text:
          " Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus laudantium mollitia corrupti.",
        link: "https://www.google.es/",
        src: "https://picsum.photos/720/400",
      };
      this.getCases();
    },

    cleanUpdate() {
      this.slcCaseSuccess = {};
      this.getCases();
    },

    modalDelete() {
      this.dialogDelete = true;
    },

    add: function() {
      this.isLoading = true;
      this.$http
        .post(this.api + "success", this.toFormData(this.newCaseSuccess))
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanAdd();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    update: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + "success/" + this.slcCaseSuccess.id_article,
          this.toFormData(this.slcCaseSuccess)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    deleteCaseSuccessConfirm() {
      this.isLoading = true;
      this.$http
        .delete(this.api + "article/" + this.slcCaseSuccess.id_article)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
          this.closeDelete();
        });
    },

    changeStatus() {
      this.$http
        .get(this.api + "article/status/" + this.slcObjetive.id_article)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.slcCaseSuccess = {};
      });
    },

    previewAdd(event) {
      this.newCaseSuccess.src = URL.createObjectURL(event);
    },

    previewUpdate(event) {
      this.slcCaseSuccess.src = URL.createObjectURL(event);
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      img: "getImg",
      actions: "getCurrentActions",
    }),
  },
};
</script>

<style>
.v-tooltip__content {
  pointer-events: initial;
}
</style>
